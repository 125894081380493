import * as echarts from 'echarts'
import {
  getUserCount,
  getDeviceCount,
  getDeviceStateDataCount,
  getAgeSexStatisInfo,
  getDeviceTypeReportInfos,
  getWarningStateDataCount,
  getWarningTypeReportInfos,
  getHealthDataTypeReportInfos,
  getWarningInfosWithDay,
  getWarningInfosWithMonth,
  getDeviceWarningLngLatInfos,
  getDeviceMapInfos,
  getUserInfos,
  getUnitStatisInfo
} from '../../../../api/api'
import deviceMapInfo from './device-map-info'
import userMapInfo from './user-map-info'
import session from "../../../../store/store";
import healthDataJs from "./health-data"
import ageDataJs from "./age-data"
import deviceDataJs from "./device-data"
import warningDataJs from "./warning-data";
import warningReportJs from "./warning-report";
import unitDataJs from "./unit-data";
import zhCn from'element-plus/lib/locale/lang/zh-cn'
import until from '../../../../until/until'

export default {
   data() {
      return {
        zhCn,
        role:session.getLoginInfo().accountType,
        sysLogoUrl:session.getSysLogoUrl(),
        sysName: session.getSysName(),
        emptyText:'加载中',
        map:null,
        infoWindow:null,
        iconRed:null,
        iconBlue:null,
        dateType:'2',
        monthPicker: '',//月选择器绑定值
        yearPicker: "", //年选择器绑定值
        dataList:[],
        currentPage: 0,
        size: 50,
        total: 0,//总数
        totalNum:0,//临时总数
        devicePlain:true,
        userPlain:false,
        user:{
          total:0,
          male:0,
          female:0
        },
        device:{
          total:0,
        },
        deviceState:{
          onLine:0,
          offLine:0
         },
         warningState:{
          total:0,
          processed:0,
          notProcessed:0
         },
         userTimeId:null,
         deviceTimeId:null,
      };
   },

   unmounted() {
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }
  },
   mounted() {
      this.init();
   },

   methods: {
     init() {
        this.magnify();
        this.getNowDateMonth()
        this.getUserCount()
        this.getDeviceCount()
        this.getDeviceStateDataCount()
        this.getAgeSexStatisInfo()
        this.getDeviceTypeReportInfos()
        this.getWarningStateDataCount()
        this.getWarningTypeReportInfos()
        this.getWarningInfosWithMonth()
        this.getHealthDataTypeReportInfos()
        this.initMap()
        
        
        if(1 == this.role) {
          this.getUnitStatisInfo()
        }else {
          this.getDeviceWarningLngLatInfos()
        }
      
     },

    getUserCount() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getUserCount(param).then(res => {
        if (res.data.code == 200) {
          var data = res.data.dataInfo;
          this.user.total = data.total
        }
      })
    },
    getDeviceCount() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getDeviceCount(param).then(res => {
        if (res.data.code == 200) {
          var data = res.data.dataInfo;
          this.device.total = data.total
        }
      })
    },
    getDeviceStateDataCount() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getDeviceStateDataCount(param).then(res => {
        if (res.data.code == 200) {
          this.deviceState = res.data.dataInfo;
        }
      })
    },
    getAgeSexStatisInfo() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getAgeSexStatisInfo(param).then(res => {
        if (res.data.code == 200) {
          var ageList = []
          var data = res.data.dataInfo;
          for(var i = 0; i < data.ageList.length; i++) {
            var name = ''
            if( i == 0) {
              name = '0-6(童年)'
            }else  if( i == 1) {
              name = '7-17(少年)'
            }else  if( i == 2) {
              name = '18-40(青年)'
            }else  if( i == 3) {
              name = '41-65(中年)'
            }else  if( i == 4) {
              name = '66以上(老年)'
            }
            ageList.push({'value':data.ageList[i],'name':name})
          }
          ageDataJs.setAgeDataChart(echarts,ageList)
          data.maleList.forEach(element => {
            this.user.male += element
          });
          data.femaleList.forEach(element => {
            this.user.female += element
          });
        }
      })
    },

    getDeviceTypeReportInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getDeviceTypeReportInfos(param).then(res => {
        var deviceTypeList = []
        if (res.data.code == 200) {
          var deviceType = res.data.dataInfo;
          
          deviceType.forEach(element => {
            deviceTypeList.push({'value':element.number,'name':element.typeDescribe})
          });
          deviceDataJs.setDeviceDataChart(echarts,deviceTypeList)
        }else {
          deviceTypeList.push({'value':0,'name':'其它'})
          deviceDataJs.setDeviceDataChart(echarts,deviceTypeList);
        }
      })
    },

    getWarningStateDataCount() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
       getWarningStateDataCount(param).then(res => {
        if (res.data.code == 200) {
          this.warningState = res.data.dataInfo;
          this.warningState.total = this.warningState.processed + this.warningState.notProcessed
        }
      })
    },

    getWarningTypeReportInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getWarningTypeReportInfos(param).then(res => {
        var warningTypeList = []
        if (res.data.code == 200) {
          var warningType = res.data.dataInfo;
          
          warningType.forEach(element => {
            warningTypeList.push({'value':element.number,'name':element.typeDescribe})
          });
          warningDataJs.setWarningDataChart(echarts,warningTypeList);
        }else {
          warningTypeList.push({'value':0,'name':'其它'})
          warningDataJs.setWarningDataChart(echarts,warningTypeList);
        }
      })
    },

    getHealthDataTypeReportInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getHealthDataTypeReportInfos(param).then(res => {
        var list = []
        if (res.data.code == 200) {
          var data = res.data.dataInfo;
          
          data.forEach(element => {
            list.push({'value':element.number,'name':element.typeDescribe})
          });
          healthDataJs.setHealthDataChart(echarts,list)
        }else {
          list.push({'value':0,'name':'其它'})
          healthDataJs.setHealthDataChart(echarts,list)
        }
      })
    },

    getUnitStatisInfo() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getUnitStatisInfo(param).then(res => {
        if (res.data.code == 200) {
          var data = res.data.dataInfo
          unitDataJs.setUnitDataChart(echarts,data.unitNameList,data.deviceList,data.userList)
        }else {
          unitDataJs.setUnitDataChart(echarts,[''],[0],[0])
        }
      })
    },

    getWarningInfosWithDay(){
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        dateTime:this.monthPicker
      }
      getWarningInfosWithDay(param).then(res => {
        if (res.data.code == 200) {
          var list = res.data.dataInfo;
          var dataList = []
          list.forEach(data => {
            dataList.push(data.number)
          });
          this.initWarningReport(dataList)
        }
     
      })
    },

    getWarningInfosWithMonth(){
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        dateTime:this.monthPicker
      }
      getWarningInfosWithMonth(param).then(res => {
        if (res.data.code == 200) {
          var list = res.data.dataInfo;
          var dataList = []
          list.forEach(data => {
            dataList.push(data.number)
          });
          this.initWarningReport(dataList)
        }
     
      })
    },

    initWarningReport(list) {
      warningReportJs.setWarningReportChart(echarts,list,this.dateType,this.yearPicker);
    },
    getDeviceWarningLngLatInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,//83d4d390ff8080810183d53065340003
        role:this.role,
        page:0,
        size:4,
      }
      getDeviceWarningLngLatInfos(param).then(res => {
        if (res.data.code == 200) {
          this.dataList = res.data.dataInfo;
        }else {
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    initMap() {
      this.map = new AMap.Map('map', {
        resizeEnable: true,
        zoom: 17,
      });
      
      this.infoWindow = new AMap.InfoWindow({
          // isCustom: true,  //使用自定义窗体
        offset: new AMap.Pixel(7, 0),
      });

      this.iconBlue = new AMap.Icon({
        image : require('../../../../assets/img/point-green.png'),
        size : new AMap.Size(14,23)
      });
      this.iconRed = new AMap.Icon({
        image : require('../../../../assets/img/point-red.png'),
        size : new AMap.Size(14,23)
      });

      window.ignore = (res) => {
        // 使用_this可以继续调用vue中的属性和方法，也可以完成绑定事件
        console.log("ignore",res);
        this.dealPage = true
        setTimeout(() => {
          this.$refs.dealView.getParentData(this.deviceData,0);
        }, 200);
      },
      window.dealWith = (res) => {
        // 使用_this可以继续调用vue中的属性和方法，也可以完成绑定事件
        console.log("dealWith",res);
        this.dealPage = true
        setTimeout(() => {
          this.$refs.dealView.getParentData(this.deviceData,1);
        }, 200);
      }
      this.initData()
    },

    initData() {
      this.getUserInfos()
    },
    getUserInfos() {
      if(this.devicePlain == false) {
        return
      }
      let param = {
        uuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage,
        size:this.size,
      }
      getUserInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.totalNum = this.totalNum + res.data.dataInfo.length
          this.currentPage++
          this.addUserMarker(res.data.dataInfo)
          this.setUserTime()
        }
      })
    },

    getDeviceMapInfos() {
      if(this.userPlain == false) {
        return
      }
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage,
        size:this.size,
      }
      getDeviceMapInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.totalNum = this.totalNum + res.data.dataInfo.length
          this.currentPage++
          this.addDeviceMarker(res.data.dataInfo)
          this.setDeviceTime()
        } 
      })
    },

    setUserTime() {
      if(this.totalNum >= this.total) {
        return
      }
      this.userTimeId = setTimeout(() => {
        this.getUserInfos()
      }, 2000);
    },

    setDeviceTime() {
      if(this.totalNum >= this.total) {
        return
      }
      this.deviceTimeId = setTimeout(() => {
        this.getDeviceMapInfos();
      }, 2000);
    },

    addUserMarker(dataList) {
      var markers = []
      dataList.forEach(data => {
        let location = data.location.split(",");//"117.025209,23.417792"
        data.lat = location[1]
        data.lng = location[0]
        if((data.lat != '0') ||(data.lng != '0')) {
          var marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconBlue, // 添加 Icon 实例
            })

          data.address = data.address.replaceAll("#","");

          marker.on('click', () => {
         
            this.infoWindow.setContent(userMapInfo.getContent(data));
           
            if(!this.infoWindow.getIsOpen()) {
                this.infoWindow.open(this.map, marker.getPosition());
            }else {
              this.infoWindow.close();
            }
          });
          markers.push(marker)
        }
      });

      if(markers.length > 0) {
        var overlayGroups = new AMap.OverlayGroup(markers);
        this.map.add(overlayGroups);
        this.map.setFitView();
      }
      
    },

    addDeviceMarker(dataList) {
      var markers = []
      dataList.forEach(data => {
        if((data.lat != '0') ||(data.lng != '0')) {
          var marker = null
          if(data.onLine == 1) {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconBlue, // 添加 Icon 实例
            })
            data.onLineStr = "在线"
            this.validOnLine++
          }else {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconRed, // 添加 Icon 实例
            })
            data.onLineStr = "离线"
            this.validOffLine++
          }

          this.validTotal = this.validOnLine + this.validOffLine

          if(data.elec == 0) {
            data.elec = '--'
          }else {
            data.elec = data.elec + '%'
          }

          if (until.isNULL(data.userName)) {
            data.userName = '未绑定'
          }

          data.address = data.address.replaceAll("#","");

          marker.on('click', () => {
            if(data.deviceType == "穿戴设备") {
              this.infoWindow.setContent(deviceMapInfo.getWearDeviceContent(data));
            }else {
              this.infoWindow.setContent(deviceMapInfo.getSmartDeviceContent(data));
            }
            if(!this.infoWindow.getIsOpen()) {
                this.infoWindow.open(this.map, marker.getPosition());
            }else {
              this.infoWindow.close();
            }
          });
          markers.push(marker)
        }
      });

      if(markers.length > 0) {
        var overlayGroups = new AMap.OverlayGroup(markers);
        this.map.add(overlayGroups);
        this.map.setFitView();
      }
      
    },

    userClick() {
      if(this.userPlain == false) {
        return
      }
      if(null != this.deviceTimeId) {
        clearTimeout(this.deviceTimeId)
      }
      if(null != this.userTimeId) {
        clearTimeout(this.userTimeId)
        this.userTimeId = null
      }
      this.map.clearMap()
      this.currentPage = 0
      this.total = 0
      this.totalNum = 0
      this.userPlain= false
      this.devicePlain = true
      this.getUserInfos()
    },
    deviceClick() {
      if(this.devicePlain == false) {
        return
      }
      if(null != this.deviceTimeId) {
        clearTimeout(this.deviceTimeId)
        this.deviceTimeId = null
      }
      if(null != this.userTimeId) {
        clearTimeout(this.userTimeId)
      }
      this.map.clearMap()
      this.currentPage = 0
      this.total = 0
      this.totalNum = 0
      this.userPlain= true
      this.devicePlain = false
      this.getDeviceMapInfos()
    },

            /**
      * 默认当前月
      */
    getNowDateMonth() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      var defaultMonthDate = year + "-" + month;
      this.monthPicker = defaultMonthDate;
      this.yearPicker = year.toString();
    },
        //选择类型
    changDateType(e) {
      this.dateType = e
      setTimeout(() => {
        if (this.dateType == 1) {
          this.getWarningInfosWithDay();
        } else if (this.dateType == 2) {
          this.getWarningInfosWithMonth();
        }
      }, 500);
    },

     //选择月份
    changMonth() {
      this.getWarningInfosWithDay()
    },
    //选择年份
    changYear() {
      this.getWarningInfosWithMonth()
    },
    


    magnify() {
      const routeData = this.$router.resolve({
          path: "/user-report-full-screen",
          query: {
              type: 1     //全屏
           } 
        })
      window.open(routeData.href)
    }
  }

     
};